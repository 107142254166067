import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import users from '../../../../assets/fullUsers.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRankingStar, faStarSharp, faStar } from '@fortawesome/free-solid-svg-icons'
const Bigsection = styled.div`
background-color: #F9FEF9;
padding: 200px 0;
/* height: 100vh; */
`
const Hero = styled.div`
text-align:center;
position: relative;
`
const ThisHeader = styled.p`
text-transform: uppercase;
font-size: 30px;
`
const Img = styled.img`
position: absolute;
top: 0;
left: 0;
width: 100%;
@media (max-width:1000px) {
    top:50px;
}
@media (max-width:768px) {
    top:100px;
}
`
const CustomeDiv = styled.div`
width:50%;
margin:auto;
background-color:white;
margin-top:300px;
@media (max-width:700px) {
    width:100%;
}`
const Starspan = styled.span`
`
const Testominals = () => {
    return (
        <Bigsection>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Hero>
                            <ThisHeader>testmonials</ThisHeader>
                            <p>Our Clients send us bunch of smilies with our services and we love them</p>
                            <Img src={users}></Img>
                            <CustomeDiv>
                                <div style={{ padding: "70px 0" }}>
                                    <Swiper
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        // onSlideChange={() => console.log('slide change')}
                                        // onSwiper={(swiper) => console.log(swiper)}
                                        // navigation
                                        pagination={{ clickable: true }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay]}
                                    // scrollbar={{ draggable: true }}
                                    >
                                        <SwiperSlide><p>Customer Testimonials I live in the Whittier area. All Premium Contractors came to my home and they installed my brand new solar system. I appreciated the entire crew who came in and did a very thorough and professional job. I would highly recommend them to others because they were extremely on point. Because of what they did at my home, I will now expect no electric bills, making us very happy. Thanks APC!
                                           </p>
                                            <h3> ED SANCHEZ Whittier, CA </h3>
                                            <p><FontAwesomeIcon color='orange' icon={faStar} /><FontAwesomeIcon style={{ padding: "0 7px" }} color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon style={{ padding: "0 7px" }} color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon color='orange' icon={faStar}></FontAwesomeIcon></p>
                                        </SwiperSlide>
                                        <SwiperSlide><p>They were professional from the start and not pushy like some of the other solar companies, that just wanted me to sign papers right away. `They were done with the install in TWO weeks, not six months like `other companies had estimated. Edison took a month to certify/inspect `and credit my electric bill. my bill has dropped to Ten dollars a `month! compared to 7- 8 hundred a month, I couldn't believe it, but `it's true. I wish they would have knocked on my door sooner, I would `have saved a lot of money with my solar panels. </p>
                                            <h3>Tray Galbreath Savannah, GA</h3>
                                            <p><FontAwesomeIcon color='orange' icon={faStar} /><FontAwesomeIcon style={{ padding: "0 7px" }} color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon style={{ padding: "0 7px" }} color='orange' icon={faStar}></FontAwesomeIcon><FontAwesomeIcon color='orange' icon={faStar}></FontAwesomeIcon></p>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>"We are very pleased with the way Besnik handled our purchase of a lake home. He was prompt, friendly, and very knowledgeable. He followed up on any and all concerns.</SwiperSlide>
                                        <SwiperSlide>"We are very pleased with the way Besnik handled our purchase of a lake home. He was prompt, friendly, and very knowledgeable. He followed up on any and all concerns.</SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </CustomeDiv>
                        </Hero>
                    </div>
                </div>
            </div>
        </Bigsection>
    );
}

export default Testominals;