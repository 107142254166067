import React from "react"
import styled from "styled-components"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import TabContainer from 'react-bootstrap/TabContainer'
import TabContent from 'react-bootstrap/TabContent'
import TabPane from 'react-bootstrap/TabPane'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import bg from '../../../../assets/realStatebg.png'
import photo1 from '../../../../assets/kitchen.jpg'
import photo2 from '../../../../assets/soalronhome.jpg'
import photo3 from '../../../../assets/roofing.jpg'
import photo4 from '../../../../assets/commercial.jpg'

import './realState.css'

const Bigsection = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
height: 100vh;
`
const Hero = styled.div`
width: 80%;
margin: auto;
`
const ViewButton = styled.button`
color:white;
background-color:#A0C632;
border: none;
padding: 20px 30px;
border-radius: 5px;
 `
const Thisheader = styled.p`
font-size:30px;
font-weight:200;`
const HouseImages = styled.div`
display: inline-block;
`
const HouseImage = styled.img`
width: 100%;
`
const SliderDiv = styled.div`
display: none;
@media (max-width:770px) {
    display: block;
}
img{
    width: 70%;
}
`
const NormalTab=styled.div`
@media (max-width:770px) {
    display: none;
}

`
const RealStateDeals = () => {
    const [key, setKey] = React.useState('Residential Property');
    return <Bigsection>
        <div className="container">
            <Hero className="row">
                <div className="col-md-6">
                    <div style={{ textAlign: "left" }}>
                        {/* <Thisheader>Best Real Estate Deals </Thisheader>
                        <p>We collaborate with organizations that are keen to transform the legal industry with tech.</p> */}
                    </div>

                </div>
                <div className="col-md-6">
                    <div style={{ textAlign: "right" }}>
                        {/* <ViewButton>View All Property</ViewButton> */}

                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <Tabs
                            defaultActiveKey="Residential Property"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="Residential Property" title="Residential Property" >
                            <NormalTab>
                                <div className="row">
                               
                                    <div className="col-md-4">
                                        <HouseImage src={photo1}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo2}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo3}></HouseImage>
                                    </div>
                                </div>
                                </NormalTab>
                                    {/* Slides for small screens */}
                                    <SliderDiv>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide>   <HouseImage src={photo1}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo2}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo3}></HouseImage></SwiperSlide>


                                        </Swiper>
                                    </SliderDiv>
                            </Tab>
                            <Tab eventKey="Commercial Property" title="Commercial Property">
                            <NormalTab>
                                <div className="row">
                                    <div className="col-md-4">
                                        <HouseImage src={photo4}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo3}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo1}></HouseImage>
                                    </div>

                                </div>
                                </NormalTab>
                                <SliderDiv>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide>   <HouseImage src={photo1}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo2}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo3}></HouseImage></SwiperSlide>


                                        </Swiper>
                                    </SliderDiv>
                            </Tab>
                            {/* <Tab eventKey="Agriculture Property" title="Agriculture Property">
                            <NormalTab>
                                <div className="row">
                                    <div className="col-md-4">
                                        <HouseImage src={photo2}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo1}></HouseImage>
                                    </div>

                                    <div className="col-md-4">
                                        <HouseImage src={photo3}></HouseImage>
                                    </div>


                                </div>
                                </NormalTab>
                                <SliderDiv>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide>   <HouseImage src={photo1}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo2}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo3}></HouseImage></SwiperSlide>


                                        </Swiper>
                                    </SliderDiv>
                            </Tab> */}
                            {/* <Tab eventKey="Industrial Property" title="Industrial Property">
                            <NormalTab>      
                                <div className="row">
                                    <div className="col-md-4">
                                        <HouseImage src={photo2}></HouseImage>
                                    </div>
                                    <div className="col-md-4">
                                        <HouseImage src={photo1}></HouseImage>
                                    </div>

                                    <div className="col-md-4">
                                        <HouseImage src={photo3}></HouseImage>
                                    </div>

                                </div>
                                </NormalTab>
                                <SliderDiv>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide>   <HouseImage src={photo1}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo2}></HouseImage></SwiperSlide>
                                            <SwiperSlide>   <HouseImage src={photo3}></HouseImage></SwiperSlide>


                                        </Swiper>
                                    </SliderDiv>
                            </Tab> */}
                        </Tabs>
                    </div>
                </div>
            </Hero>
        </div>
    </Bigsection>
}
export default RealStateDeals