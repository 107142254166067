
import Footer from "../landingPage/components/Footer/Footer"
import HeaderForServices from "./HeaderForServices/HeaderForServices"
import styled from "styled-components"
import React from "react"
import bg from '../../assets/ServicesBg.png'

import img from '../../assets/doorsServices.png'


import house from '../../assets/bigHouseimg.png'
import { useLocation } from "react-router-dom"

// Style
const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */
`
const LeftDiv = styled.div``

const RightDiv = styled.div``

const Img = styled.img`
width:50%;
margin-right:100px;
`

const Content = styled.div`
padding: 100px 0;
`
const BlackDot = styled.span`
  height: 4px;
  width: 4px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
`
const WindowsAndDoors = () => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>

            <div className="container">
                <div className="row" style={{ padding: "100px 0" }}>
                    <LeftDiv style={{ textAlign: "right" }} className="col-md-6">
                        <Img src={img}></Img>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p style={{ fontSize: "16px" }}>Energy efficient windows are an important consideration for both new and existing homes. Heat gain and heat loss through windows are responsible for 25%–30% of residential heating and cooling energy use. If you are selecting windows for new construction or to replace existing windows, it’s important to choose the most efficient windows in your climate.
                            </p>
                            <p>Strong, beautiful, long lasting and durable. Milgard windows and patio doors are designed with stunning architectural style and superior performance:</p>
                            <p> <BlackDot></BlackDot> Feel secure in your investment with industry leading warranties.</p>
                            <p>  <BlackDot></BlackDot>  Choose from the highest quality materials and styles designed not only to complement your home, but open and close easily.</p>
                            <p> <BlackDot></BlackDot>  Express your creativity and dramatically increase your home’s curb appeal with grid options and trim colors</p>
                            {/* <p style={{ fontSize: "16px" }}>
                                We educate our clients and walk them through the entire bathroom renovation process, allowing them to not just appreciate the value of their new space but to experience the quality of their new space from every aspect. Home Improvement can help you choose layouts, bathroom design ideas, create goals, and completely customize this space to reflect your personal style and needs.
                            </p>

                            <p style={{ fontSize: "22px" }}>Our full array of bathroom remodeling services includes:</p>
                            <ul>
                                <li>3D design layout of your bathroom</li>
                                <li>Cabinet and vanity installation</li>
                                <li>Plumbing installation</li>
                                <li>Electrical installation</li>
                                <li>Fixture and lighting installation</li>
                                <li>Replacing and/or upgrading tile showers</li>
                                <li>Flooring</li>
                                <li>Painting</li>
                            </ul> */}
                        </Content>
                    </RightDiv>
                </div>
            </div>
        </Hero>
        <Footer></Footer>
    </div>
}
export default WindowsAndDoors