import React from "react"
import styled from "styled-components"
import image from '../../../../assets/Image2332.png'
const Bigsection = styled.div`
background-color: #0D3350;
@media (max-width:426px) {
    margin-top: 100px !important;
}
`

const LeftDiv = styled.div`
position:relative;`
const Image = styled.img`
width:60%;
position: absolute;
right: 100px;
bottom: 20px;
@media (max-width:900px) {
    position: relative;
    width: 100%;
    left: 0;
}
@media (max-width:767px) {
    position: relative;
    width: 50%;
    left: 0;
    top:20px;
}

`
const Lorem=styled.p`
padding-top: 20px;
width: 70%;
@media (max-width:900px) {
    width: 100%;
} 
`
const WhatWeDo = () => {
    return <Bigsection>
        <div className="container">
            <div className="row">
                <LeftDiv className="col-md-6">
                    <Image src={image}></Image>
                </LeftDiv>
                <div className="col-md-6">
                    <div style={{ padding: "50px 0", color: "white" }}>
                        <h1>WHAT WE DO</h1>
                        <Lorem>All Premium Contractors’ number one priority is customer satisfaction. Our company has participated in all types of construction projects with the highest degree of quality workmanship. All Premium Contractors offers a wide variety of services that cover basically all construction projects.
                            We strive in offering our clients reliable, courteous, and quality workmanship. We work only with highly experienced sub-contractors with strong project records. They use those skills and knowledge in a clear and logical way to help navigate our clients and make their project a reality.</Lorem>
                    </div>
                </div>
            </div>
        </div>
    </Bigsection>
}
export default WhatWeDo