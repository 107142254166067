import React from "react"
import styled from "styled-components"

import solar from '../../../../assets/Solar.png'
import room from '../../../../assets/icon 03.png'
import roofing from '../../../../assets/Roofing.png'
import bath from '../../../../assets/Bathroom.png'
import flooring from '../../../../assets/Flooring.png'
import windows from '../../../../assets/Windows.png'
import bg from '../../../../assets/bgforsecondsection.png'
import bg2 from '../../../../assets/bgforsecondsectiontwo.png'
import living from '../../../../assets/Image.png'
import { Link } from "react-router-dom"

// Style
const Bigsection = styled.div`
padding: 100px 0;

`
const BackgroundImage = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
`
const ThisHeader = styled.div`
text-align: center;
`
const DivContent = styled.div`
text-align:center;
width: 80%;
margin: auto;
font-size: 10px;
/* max-height: 150px;
overflow-y: scroll; */
/* width */
&::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: #a0c632; 
  border-radius: 10px;
}

/* Handle on hover */
&:nth-child(3){
    margin-top:25px ;
}
`
const Img = styled.img`
width: 20%;
`
const SixthDiv = styled.div`
text-align:center;
box-shadow: 4px 4px 5px 0px  rgba(0,0,0,0.02);
margin: 80px 0;
transition: 1s all;
transition: width 1s, height 1s, transform 1s;

&:hover{
    /* transform: scale(1.5);  */
	/* transition: opacity 0.45s, transform 0.45s; */
	/* transform: rotate3d(0,0,1,360deg) scale(1.4) ; */
    /* rotate(-5deg)  */
  transform:  scale(1.2);
  /* background-color: #ffffff; */
    z-index: 2;

}
`
const SecondDiv = styled.div`
background-position: top;
background-repeat: no-repeat;
background-size: cover;
height: 60vh;
width: 100%;
position: relative;
`
const SecondDivContainer = styled.div`
text-align:center;
margin:auto;
/* position: relative; */
`
const CustomeDiv = styled.div`
-webkit-border-top-left-radius: 100px;
-webkit-border-bottom-left-radius: 100px;
-moz-border-radius-topleft: 100px;
-moz-border-radius-bottomleft: 100px;
border-top-left-radius: 100px;
border-bottom-left-radius: 100px;
width: 70%;
background-color: #A0C632;
position: absolute;
top: 0;
right: 190px;
color: white;
@media(max-width:1024px){
    right: 130px;
}
@media(max-width:700px){
display:none;
}
`
const CustomeDivSmall = styled.div`
margin: auto;
width: 100%;
background-color: #A0C632;
color: white;
display: none;
text-align: center;
@media(max-width:700px){
display:block;
}
`
const RigntDIv = styled.div`
@media(max-width:700px){
    padding: 0;
}
`
const SecondDivLeft = styled.div``
const LivingImage = styled.img`
position: absolute;
width: 70%;
top: -120px;
left: -130px;
@media (max-width:1024px) {
width: 70%;
top: -70px;
left: -100px;
}
@media (max-width:768px) {
display: none;
}
`
const Input = styled.input`
border-radius: 3px;
margin:15px 0;
border: none;
padding: 10px 25px;
display: block;
width: 70%;
text-align: left;
@media(max-width:700px){
margin : 10px auto;
}
transition: 1s all;
&:focus{
    outline: none !important;
    border:2px solid orange;
    /* box-shadow: 0 0 10px #719ECE; */
}
`
const InputTwo = styled.input`
border-radius: 3px;
margin:15px 0;
border: none;
padding: 50px 25px;
display: block;
width: 70%;
text-align: left;
@media(max-width:700px){
margin : 10px auto;
}`
const ButtonSubmit = styled.button`
padding: 10px 0;
width: 70%;
border: none;
border-radius: 5px;
background-color: white;
color: #A0C632;
font-size: 20px;
`
const BlackDot = styled.span`
  height: 4px;
  width: 4px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
`
const SpecialP = styled.p`
  /* line-height: 1.4; */
  margin:2px 0;
  /* text-align:left; */
  `
  const LearnButton=styled.button`
  border: none;
  /* background-color: #A0C632; */
  padding: 0;
  background: transparent;
  color: #A0C632;
  font-size: 12px;
  `
const HomeImprovment = () => {
    const rand = (min, max) => Math.floor(Math.random() * (max - min)) + min
    return <div>
        <BackgroundImage>
            <Bigsection className="container">
                <div className="row">
                    <ThisHeader className="col-md-12">
                        <p style={{ fontSize: "32px", fontWeight: "bold" }}>THE ONLY HOME IMPROVEMENT COMPANY YOU NEED</p>
                        <p style={{ width: "70%", margin: "auto" }}>All Premium Contractors has everything you need to get you the remodeled home you’ve always wanted. Our experienced design professionals will work with you from idea to installation, ensuring your project turns out just the way you envision.</p>
                    </ThisHeader>
                </div>
                {/* photo section */}
                <div className="row" style={{ padding: "50px 0", width: "70%", margin: "auto" }}>
                    <SixthDiv className="col-md-4">
                        <Img src={solar}></Img>
                        <DivContent>
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>Solar</p>
                            <p>Going Solar in the most cost-effective and quickest return on your investment! Most average a complete return in as little as 4 years! Let’s work together to take control of you power usage, and carbon footprint...<Link to="/Solar"> <LearnButton>see more </LearnButton></Link></p>
                            {/* <p>With 35+ filters and custom keyword search, Trulia can help you find a home.</p> */}
                        </DivContent>
                    </SixthDiv>

                    <SixthDiv className="col-md-4" >
                        <Img src={flooring}></Img>
                        <DivContent>
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>Flooring</p>
                            <p>Quality flooring can be high, but it to improve the value of the property. A house with hardwood flooring tends to be sold faster, which reflects how the choice of flooring tends to upgrade the value of a place...<Link to="/Flooring"> <LearnButton>see more </LearnButton></Link></p>
                        </DivContent>
                    </SixthDiv>

                    <SixthDiv className="col-md-4">
                        <Img src={bath}></Img>
                        <DivContent>
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>Bathroom</p>
                            <p>Let’s design together
                                With our all-inclusive, design-build process you can skip the headaches, cost increases, and nonsense – we handle every detail from start to finish...<Link to="/BathRooms"> <LearnButton>see more</LearnButton></Link></p>
                        </DivContent>
                    </SixthDiv>
                    <SixthDiv className="col-md-4">
                        <Img src={windows}></Img>
                        <DivContent>
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>Windows</p>
                            <p>Strong, beautiful, long lasting and durable. Milgard windows and patio doors are designed with stunning architectural style and superior performance:
                                Feel secure in your investment with industry leading warranties...<Link to="/WindowsAndDoors"> <LearnButton>see more </LearnButton></Link>
                            </p>
                        </DivContent>
                    </SixthDiv>
                    <SixthDiv className="col-md-4">
                        <Img src={roofing}></Img>
                        <DivContent>
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>Roofing</p>
                            <p>Choose the best roofing to prevent water damage and stay on budget. Our testing emphasizes strength and includes results for weight, wind, nailing, and impact.
                            </p>
                        </DivContent>
                    </SixthDiv>
                    <SixthDiv className="col-md-4">
                        <Img src={room}></Img>
                        <DivContent >
                            <p style={{ paddingTop: "25px", fontSize: "20px" }}>HVAC</p>
                            <p>HVAC services include construction, installation, and servicing of heating, cooling, and ventilation systems and equipment.<Link to="/AirConditioning"><LearnButton>...see more </LearnButton></Link>
                                </p>
                        </DivContent>
                    </SixthDiv>
                </div>
                <SecondDiv >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <CustomeDiv>
                                                <div className="row" style={{ padding: "40px 0" }}>
                                                    <div className="col-md-6">
                                                        <LivingImage src={living}></LivingImage>
                                                    </div>
                                                    <RigntDIv className="col-md-6">
                                                        <h3>GET A FREE QUOTE</h3>
                                                        <form>
                                                            <Input placeholder="Your Name"></Input>
                                                            <Input placeholder="Email Address"></Input>
                                                            <Input placeholder="Your Phone"></Input>
                                                            <InputTwo placeholder="Description"></InputTwo>
                                                            <ButtonSubmit>SEND REQUEST</ButtonSubmit>
                                                        </form>
                                                    </RigntDIv>
                                                </div>

                                            </CustomeDiv>


                                            <CustomeDivSmall>
                                                <div className="row" style={{ padding: "40px 0" }}>
                                                    <RigntDIv className="col-md-12">
                                                        <h3>GET A FREE QUOTE</h3>
                                                        <form>
                                                            <Input placeholder="Your Name"></Input>
                                                            <Input placeholder="Email Address"></Input>
                                                            <Input placeholder="Your Phone"></Input>
                                                            <InputTwo placeholder="Description"></InputTwo>
                                                            <ButtonSubmit>SEND REQUEST</ButtonSubmit>
                                                        </form>
                                                    </RigntDIv>
                                                </div>
                                            </CustomeDivSmall>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SecondDiv>
            </Bigsection>
        </BackgroundImage>
        {/* Second div */}

    </div>
}
export default HomeImprovment