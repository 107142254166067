import Footer from "../landingPage/components/Footer/Footer"
import HeaderForServices from "./HeaderForServices/HeaderForServices"
import styled from "styled-components"
import React from "react"
import bg from '../../assets/ServicesBg.png'
import img from '../../assets/bathroomServices.png'
import { useLocation } from "react-router-dom";

import house from '../../assets/bigHouseimg.png'

const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */
`
const LeftDiv = styled.div``

const RightDiv = styled.div``

const Img = styled.img`
width:50%;
margin-right:100px;
`

const Content = styled.div`
padding: 100px 0;
`

const BathRooms = () => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>

            <div className="container">
                <div className="row" style={{ padding: "100px 0" }}>
                    <LeftDiv style={{ textAlign: "right" }} className="col-md-6">
                        <Img src={img}></Img>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p style={{ fontSize: "16px" }}>Our bathrooms reflecting our clients’ personal taste while seamlessly blending in aesthetics with function and solid workmanship. Your bathroom will be custom designed by one of our highly skilled Project Managers using top quality materials from brands you know and trust. We do not use any liner systems or take short cuts to accomplish our work.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                We educate our clients and walk them through the entire bathroom renovation process, allowing them to not just appreciate the value of their new space but to experience the quality of their new space from every aspect. Home Improvement can help you choose layouts, bathroom design ideas, create goals, and completely customize this space to reflect your personal style and needs.
                            </p>

                            <p style={{ fontSize: "22px" }}>Our full array of bathroom remodeling services includes:</p>
                            <ul>
                                <li>3D design layout of your bathroom</li>
                                <li>Cabinet and vanity installation</li>
                                <li>Plumbing installation</li>
                                <li>Electrical installation</li>
                                <li>Fixture and lighting installation</li>
                                <li>Replacing and/or upgrading tile showers</li>
                                <li>Flooring</li>
                                <li>Painting</li>
                            </ul>
                        </Content>
                    </RightDiv>
                </div>
            </div>
        </Hero>
        <Footer></Footer>
    </div>
}
export default BathRooms