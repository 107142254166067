
import Footer from "../landingPage/components/Footer/Footer"
import HeaderForServices from "../Services/HeaderForServices/HeaderForServices"
import styled from "styled-components"
import React from "react"
import bg from '../../assets/ServicesBg.png'
import house from '../../assets/bigHouseimg.png'

// Style
const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */
`
const LeftDiv = styled.div``

const RightDiv = styled.div``

const Img = styled.img`
width:50%;
margin-right:100px;
`

const Content = styled.div`
padding: 100px 0;
`
const AboutUs = () => {
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>

            <div className="container">
                <div className="row" style={{ padding: "100px 0" }}>
                    <LeftDiv style={{ textAlign: "right" }} className="col-md-6">
                        <Img src={house}></Img>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p style={{ fontSize: "16px" }}>All Premium Contractors is dedicated to provide best quality construction and improvement  service for all projects. Our success as a business is based on a simple and time proven approach. We listen to our client’s needs and understand their expectations. We ensure to exceed your expectations by utilizing the resources from our seasoned staff.
                                We work in partnership with owners, architects, subcontractors and engineers to deliver results in Bakersfield and across California. All Premium Contractors delivers the best cost to quality ratio for each project. We specialize in helping the owner select cost-effective alternatives to maximize value.
                                Organization and good communication are fundamental in our customers’ satisfaction. All Premium Contractors is a California company with the construction license #920361. With over 10 years of experience in the Bakersfield area. We can meet all your construction needs from remodels, additions, new construction, job management and consulting.
                            </p>
                            {/* <p style={{ fontSize: "16px" }}>
                                We educate our clients and walk them through the entire bathroom renovation process, allowing them to not just appreciate the value of their new space but to experience the quality of their new space from every aspect. Home Improvement can help you choose layouts, bathroom design ideas, create goals, and completely customize this space to reflect your personal style and needs.
                            </p>

                            <p style={{ fontSize: "22px" }}>Our full array of bathroom remodeling services includes:</p>
                            <ul>
                                <li>3D design layout of your bathroom</li>
                                <li>Cabinet and vanity installation</li>
                                <li>Plumbing installation</li>
                                <li>Electrical installation</li>
                                <li>Fixture and lighting installation</li>
                                <li>Replacing and/or upgrading tile showers</li>
                                <li>Flooring</li>
                                <li>Painting</li>
                            </ul> */}
                        </Content>
                    </RightDiv>
                </div>
            </div>
        </Hero>
        <Footer></Footer>
    </div>
}
export default AboutUs