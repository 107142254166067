import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../../../../assets/logo.png'
import styled from "styled-components"
import { Link } from "react-router-dom"
import backVideo from '../../../../assets/background-video/video.mp4'

// Style
import './header.css'
const Bigsection = styled.div`
 position: relative;
  overflow: hidden;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
`
const Img = styled.img`
`
// const ButtonSign = styled.button`
// background-color: #A0C632;
// border:none;
// border-radius:5px;
// padding: 1px 25px;
// color: white;
// `
const VideoContainer = styled.div`
`
const MyVideo = styled.video`
width: 100vw; 
height: 100vh;
object-fit: cover;
position: absolute; 
left: 0px;
top: 0px;
z-index: -1;
`;
const HeroDivContainer = styled.div`
`
const HeroDiv = styled.div`
  color: white;
  margin: auto;
  text-align: left;
  padding:200px 130px;
@media (max-width:991px) {
  padding:80px 100px;
  font-size: 10px;
  }
@media (max-width:600px) {
  padding:100px 100px;
}

@media (max-width:400px) {
  padding:0 20px;
}
`
const Since = styled.p`
font-size: 30px;
font-weight: bold;
@media (max-width:991px) {
font-size: 25px;
}
@media (max-width:600px) {
    font-size: 20px;
}
`
const Location = styled.p`
font-size: 25px;
padding:20px 0;
@media (max-width:991px) {
font-size: 20px;
}
@media (max-width:600px) {
    font-size: 15px;
}
`
const Dot = styled.span`
  height:15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  @media (max-width:600px) {
    height:9px;
  width: 9px;
}

  `
const GetAquote = styled.button`
font-weight:bold;
font-size: 25px;
color:#A0C632;
background-color: white;
border: none;
border-radius: 8px;
padding: 10px 40px;
@media (max-width:768px) {
    padding: 10px 20px;
}
`
// const Bigsection = styled.div`
// background-color: #0D3350;
// `
const MyList = styled.ul`
display: flex;
list-style: none;
transition: 1s all;
line-height: 60px; 
height: 60px;
@media (max-width:991px) {
line-height: 30px; 
/* height: 50px; */
flex-direction: column;
}
li{

    padding-right: 0.7rem;
    padding-left: 0.7rem;
    color: white;
    transition: 1s all;
    position: relative;
    
    &:after{
        transition: all 1.0s;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '';
        color: transparent;
        background: #A0C632;
        height: 1.5px;
    }
    &:hover{
    color: #A0C632 !important;
    cursor: pointer;
    transition: 1s all;
    &:after{
        width: 100%;
        @media (max-width:992px) {
            width: 0%;
        }
    
    }
    &:before{
        transition: all 0.5s;
    }
    }
   
}
@media(max-width:991px){
        /* flex-direction: column; */
        text-align: left;
        margin: 0 !important;
        display: block;
    }
`
const Menu = styled.div`
display: none;
position: absolute;
top: 100%;
left: 0;
box-shadow: 10px 5px 10px rgb(0 0 0 / 13%);
background-color: #fafdf0;
width: 300px;
z-index: 333;
transition-delay: 2s;

@media (max-width:425px) {
    width: 250px !important;
}
@media (max-width:320px) {
    width: 200px !important;
}
li{
    /* padding:0px 10px;    */
    /* height: 35px; */
    line-height: 40px; 
    height: 40px;
    padding-top: 0px;
    font-size: 14px;
    border-bottom: 1px solid black ;
    color: black;
    @media (max-width:425px) {
        line-height: 20px; 
    height: 20px;
}
@media (max-width:990px) {
    line-height: 20px; 
    height: 20px;
    padding-top: 10px;
}
@media (max-width:320px) {
    line-height: 20px; 
    height: 20px;
    padding-top: 10px;
}
    @media (max-width:991px) {
         line-height:10px;   
    height: 35px;
    }
    &:after{
        content: "";
        /* position: absolute; */
        width: 0;
        height: 0;
    }
    &:hover{
        padding-left: 25px;
    }
}
`
const SpecialLi = styled.li`
transition-delay: 2s;
&:hover  ${Menu}{
   display: block;
   transition-delay: 2s;
}
`

const ButtonSign = styled.button`
background-color: #A0C632;
border:none;
border-radius:5px;
padding: 0px 50px ;
color: white;
/* @media(max-width:991px){
    margin-top: 30px;
} */

`
const NoLi = styled.li`
 &:after{
        content: "";
        width: 0 !important;
        height: 0 !important;
    }
@media (max-width:991px) {
    margin: 0 !important;

}

`

const Header = () => {
    return <Bigsection  >
        <Navbar collapseOnSelect expand="lg" variant="light" style={{ background: "transparent !important", backdropFilter: "blur(3px)", height: "70px" }} >
                    <Container style={{ margin: "auto" }}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className="container-fluid">
                            <Nav className="me-auto">
                                <MyList>
                                    <Nav>  <Link to="/">   <NoLi> <Img src={logo}></Img></NoLi></Link></Nav>
                                </MyList>
                            </Nav>
                            <Nav className="me-auto">
                                <MyList>
                                    <Nav><span style={{ color: "white" }}> <Link to="/">   <li>Home</li>   </Link></span> </Nav>
                                    <Nav ><span style={{ color: "white" }}> <SpecialLi>
                                        <span > Services</span>
                                        <Menu>
                                            <Link to="/Kitchens">  <li>Kitchens</li></Link>
                                            <Link to="/BathRooms">    <li>Bathrooms</li></Link>
                                            <Link to="/Solar">   <li>Solar</li></Link>
                                            <Link to="/LandScaping">   <li>Landscaping</li></Link>
                                            <Link to="/GarageConversion">     <li>Garage Conversion</li></Link>
                                            <Link to="/RoomAddition">  <li>Room Additions</li></Link>
                                            <Link to="/Patio">   <li>Patio</li></Link>
                                            <Link to="/pavers">   <li>Pavers</li></Link>
                                            <Link to="/Coating">  <li>Coating</li></Link>
                                            <Link to="/Flooring"> <li>Flooring</li></Link>
                                            <Link to="/WindowsAndDoors">  <li>Windows & Doors</li></Link>
                                            <Link to="/AirConditioning">   <li>Air Conditioning / HVAC</li></Link>
                                        </Menu>
                                    </SpecialLi></span></Nav>
                                    <Nav ><span style={{ color: "white" }}><Link to="/AboutUS">    <li>About us</li></Link></span></Nav>
                                    <Nav ><span style={{ color: "white" }}>  <Link to="/ContactUS"><li>Contct us</li></Link></span></Nav>
                                </MyList>
                                {/* <MyList>
                                    <Link to="/">   <li>Home</li>   </Link>


                                    <Link to="/AboutUS">    <li>About us</li></Link>
                                    <li>Contct us</li>
                                    <SpecialLi>
                                        <span > Services</span>
                                        <Menu>
                                            <Link to="/Kitchens">  <li>Kitchens</li></Link>
                                            <Link to="/BathRooms">    <li>Bathrooms</li></Link>
                                            <Link to="/Solar">   <li>Solar</li></Link>
                                            <Link to="/LandScaping">   <li>Landscaping</li></Link>
                                            <Link to="/GarageConversion">     <li>Garage Conversion</li></Link>
                                            <Link to="/RoomAddition">  <li>Room Additions</li></Link>
                                            <Link to="/Patio">   <li>Patio</li></Link>
                                            <Link to="/pavers">   <li>Pavers</li></Link>
                                            <Link to="/Coating">  <li>Coating</li></Link>
                                            <Link to="/Flooring"> <li>Flooring</li></Link>
                                            <Link to="/WindowsAndDoors">  <li>Windows & Doors</li></Link>
                                            <Link to="/AirConditioning">   <li>Air Conditioning / HVAC</li></Link>
                                        </Menu>
                                    </SpecialLi>
                                </MyList> */}

                            </Nav>
                            <Nav>
                                <MyList>
                                    <Nav style={{textAlign:"right",float:"right"}}>   <NoLi style={{ lineHeight: "40px", margin: "auto", textAlign: "left" }}><ButtonSign>Sign up</ButtonSign></NoLi></Nav>
                                </MyList>

                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
        <div className="row">
            <div className="col-md-12">
                <MyVideo id="background-video" autoPlay muted loop>
                    <source src={backVideo} type="video/mp4" />
                </MyVideo>
           

                {/* Second Navbar */}


                <HeroDiv>
                    <Since>Since 2008, Home Improvement</Since>
                    <Location> <Dot></Dot>Solar  <Dot></Dot>   Roofing  <Dot></Dot>   HVAC     <Dot></Dot>  Flooring </Location>
                    <GetAquote>GET A QUOTE</GetAquote>
                </HeroDiv>

            </div>
        </div>
    </Bigsection>
}
export default Header