import logo from './logo.svg';
import LandingPage from './pages/landingPage/landingPage';
import BathRooms from './pages/Services/BathRooms';
import GarageConversion from './pages/Services/GarageConversion';
import Kitchens from './pages/Services/Kitchens';
import LandScaping from './pages/Services/LandScaping';
import Patio from './pages/Services/Patio';
import RoomAddition from './pages/Services/roomAddition';
import Solar from './pages/Services/Solar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Pavers from './pages/Services/pavers';
import Coating from './pages/Services/Coating';
import Flooring from './pages/Services/Flooring';
import WindowsAndDoors from './pages/Services/WindowsAndDoors';
import AirConditioning from './pages/Services/AirConditioning';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUS from './pages/ContactUS/ContactUS';
function App() {
  return (
    <div>
      {/* Routes */}
      <Routes>
        <Route path="/" element={<LandingPage></LandingPage>}></Route>
        <Route path="/Kitchens" element={<Kitchens></Kitchens>}></Route>
        <Route path="/BathRooms" element={<BathRooms></BathRooms>}></Route>
        <Route path="/Solar" element={<Solar></Solar>}></Route>
        <Route path="/LandScaping" element={<LandScaping></LandScaping>}></Route>
        <Route path="/GarageConversion" element={<GarageConversion></GarageConversion>}></Route>
        <Route path="/RoomAddition" element={<RoomAddition></RoomAddition>}></Route>
        <Route path="/Patio" element={<Patio></Patio>}></Route>
        <Route path="/pavers" element={<Pavers></Pavers>}></Route>
        <Route path="/Coating" element={<Coating></Coating>}></Route>
        <Route path="/Flooring" element={<Flooring></Flooring>}></Route>
        <Route path="/WindowsAndDoors" element={<WindowsAndDoors></WindowsAndDoors>}></Route>
        <Route path="/AirConditioning" element={<AirConditioning></AirConditioning>}></Route>
        <Route path="/AboutUS" element={<AboutUs></AboutUs>}></Route>
        <Route path="/ContactUS" element={<ContactUS></ContactUS>}></Route>
      </Routes>
      {/* <LandingPage></LandingPage> */}
      {/* <Kitchens></Kitchens> */}
      {/* <BathRooms></BathRooms> */}
      {/* <Solar></Solar> */}
      {/* <LandScaping></LandScaping> */}
      {/* <GarageConversion></GarageConversion> */}
      {/* <RoomAddition></RoomAddition> */}
      {/* <Patio></Patio> */}
    </div>
  );
}

export default App;
