

import Footer from "../landingPage/components/Footer/Footer"
import HeaderForServices from "../Services/HeaderForServices/HeaderForServices"
import styled from "styled-components"
import React from "react"
import bg from '../../assets/ServicesBg.png'
import house from '../../assets/bigHouseimg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faStarSharp, faStar, faPhone, faEnvelope,faAt } from '@fortawesome/free-solid-svg-icons'

import emailjs from 'emailjs-com';
// 

// Style
const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */

h4{
    font-family: 'Beau Rivage', cursive;
    font-size: 50px;
}
`
const LeftDiv = styled.div``

const RightDiv = styled.div`
text-align:center;`

const Img = styled.img`
width:50%;
margin-right:100px;
`

const Content = styled.div`
/* padding: 100px 0; */
color: #0000009f;
`
const Input = styled.input`
width: 100%;
margin:5px 0;
padding: 1rem 0;
border: none;
&:focus{
    outline: none !important;
    border:3px solid #a0c632;
    border-radius: 5px;
    /* box-shadow: 0 0 10px #a0c632; */
}
`
const SendButton = styled.button`
width: 100%;
background-color: #a0c632;
padding: 10px 0;
border: none;
color: white;
`
const InputTwo = styled.textarea`
height:200px;
width:100%;
border: none;
&:focus{
    outline: none !important;
    border:3px solid #a0c632;
    border-radius: 5px;
    /* box-shadow: 0 0 10px #a0c632; */
}`
const MainContent = styled.div`
width: 70%;
margin: auto;
`
const ContactUS = () => {
    const sendEmail=(e)=>{
        e.preventDefault();
        emailjs.sendForm("service_k1963uf","template_0mxk3st",e.target,"jhAiT4MabPyEamuyA").then(res=>{
            console.log("message",res)
        }).catch(e=>{
            console.log("error",e)
        })
    }
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>

            <MainContent className="container py-5" >
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Contact Us</h2>
                        <h4>We Are Happy To hear from You Soon</h4>
                    </div>

                </div>
                <p>Send us email</p>
                <div className="row">
                    <LeftDiv className="col-md-6">

                        <form onSubmit={sendEmail}>
                            <Input placeholder="Name" name="name" ></Input>
                            <Input placeholder="Email Address" name="email"></Input>
                            <Input type="tel" placeholder="Phone" name="phone"></Input>
                            <InputTwo placeholder="Message" name="message"></InputTwo>
                            <SendButton>Send</SendButton>
                        </form>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p>   <FontAwesomeIcon fontSize={35} icon={faLocationDot}></FontAwesomeIcon>	</p>
                            <p style={{ fontSize: "20px" }}>22647 Ventura Blvd, Woodland Hills CA 91364</p>
                            <p> <FontAwesomeIcon fontSize={35} icon={faAt}></FontAwesomeIcon> </p>
                            <p style={{ fontSize: "20px" }}>	support@allpremiumhome.com	</p>
                            <p> <FontAwesomeIcon fontSize={35} icon={faPhone}></FontAwesomeIcon>	 </p>
                            <p style={{ fontSize: "20px" }}>	1-818-641-1240</p>
                        </Content>
                    </RightDiv>
                </div>
            </MainContent>
        </Hero>
        <Footer></Footer>
    </div>
}

export default ContactUS