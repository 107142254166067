import CallForAquote from "./components/CallForAquote/CallForAquote"
import Header from "./components/Header/header"
import HomeImprovment from "./components/Home improvement company/homeImporovment"
import RealStateDeals from "./components/RealStateDeals/RealStateDeals"
import Testominals from "./components/Testmonials/testominals"
import WhatWeDo from "./components/WhatWeDo/whatWeDo"
import Footer from "./components/Footer/Footer"


const LandingPage = () => {
    return <div>
     <Header></Header>
     <HomeImprovment></HomeImprovment>
     <Testominals></Testominals>
     <RealStateDeals></RealStateDeals>
     <WhatWeDo></WhatWeDo>
     <CallForAquote></CallForAquote>
     <Footer></Footer>
    </div>
}
export default LandingPage