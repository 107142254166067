import React from "react"
import styled from "styled-components"
import logo from '../../../../assets/logo.png'
import boxlogo from '../../../../assets/logoForBoxbyld.png'
const Bigsection = styled.div`
background-color:#A0C632;
padding:90px 0;
`

const Mylist = styled.ul`
list-style-type: none;
li{

    color:#576B81;
    line-height: 35px;
}
`
const Img = styled.img`
width: 20%;
`
const BoxImage = styled.img`
width:20%;`
const Footer = () => {
    return <Bigsection>
        <div className="container">
            <div className="row">
                {/* <div className="col-md-3">
                    <Mylist>
                        <p>Popular Searches</p>
                        <li>Apartment for Rent</li>
                        <li>Apartment Low to hide</li>
                        <li>Offices for Buy</li>
                        <li>Offices for Rent</li>
                    </Mylist>
                </div> */}
                <div className="col-md-4">
                    <Mylist>
                        <p>About Us</p>
                        <li>Our Story</li>
                        <li>Team Members </li>
                        <li>Careers</li>
                        <li>Contact Us </li>
                    </Mylist>
                </div>
                <div className="col-md-4">
                    <Mylist>
                        <p>Quick links</p>
                        <li>Terms of Use</li>
                        <li>Privacy Policy</li>
                        <li>Contact Support</li>
                        <li>FAQs</li>
                       <a href="https://www.boxbyld.tech/"> <li>BoxBYLD</li></a>
                    </Mylist>
                </div>
                <div className="col-md-4">
                    <Mylist>
                        <p>Support</p>
                        <li>Help Center</li>
                        <li>Loan Support </li>
                        <li>Management</li>
                        <li>Privacy Policy</li>
                    </Mylist>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-6">
                    <Mylist>
                        <li> <Img src={logo}></Img> <span style={{padding:"0 20px"}}> <a href="https://www.boxbyld.tech/"><BoxImage src={boxlogo}></BoxImage></a></span> </li>
                    </Mylist>

                </div>
                <div className="col-md-6">
                    {/* <p style={{textAlign:"right"}}>© 2021 Besnik. All Rights Reserved</p> */}
                    <Mylist>
                        <li style={{ color: "#707070", textAlign: "center" }}> © 2022 All Premium home. All Rights Reserved Powered By <a style={{fontSize:"25px",color:"black"}}  href="https://www.boxbyld.tech/">BoxBYLD</a> </li>
                    </Mylist>
                </div>
            </div>
        </div>
    </Bigsection>
}
export default Footer