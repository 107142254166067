import Header from "../landingPage/components/Header/header"
import Footer from "../landingPage/components/Footer/Footer"
import styled from "styled-components"
import React from "react"

import bg from '../../assets/ServicesBg.png'

import img from '../../assets/kitchenServices.png'
import house from '../../assets/bigHouseimg.png'
import HeaderForServices from "./HeaderForServices/HeaderForServices"
import { useLocation } from "react-router-dom"

const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */
`
const LeftDiv=styled.div``

const RightDiv=styled.div``

const Img=styled.img`
width:50%;
margin-right:100px;
`
    
const Content = styled.div`
padding: 100px 0;
`
const Kitchens = () => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>
            <div className="container">
                <div className="row" style={{padding:"100px 0"}}>
                    <LeftDiv style={{textAlign:"right"}} className="col-md-6">
                        <Img  src={img}></Img>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p style={{fontSize:"20px"}}>One of the most important and difficult keys to being a good kitchen contractor is to be able to balance form and function, decipher wants from needs, filter good and inferior products and in the end be able to manage the entire process into beautiful workable space. There are many types of kitchen styles, traditional, contemporary and modern looks</p>

                            <p style={{fontSize:"22px"}}>Our full array of kitchen remodeling services include: </p>
                            <ul>
                                <li>3D Design layout of your kitche</li>
                                <li>Cabinet installation</li>
                                <li>Countertop installation</li>
                                <li>3Sink and Plumbing/Re-piping</li>
                                <li>Electrical and lighting</li>
                                <li>Flooring</li>
                                <li>Painting</li>
                            </ul>
                        </Content>
                    </RightDiv>
                </div>
            </div>
        </Hero>

        <Footer></Footer>
    </div>
}

export default Kitchens