import React from "react"
import styled from "styled-components"
import bg from '../../../../assets/callForAquoteBg.png'
import bighouse from '../../../../assets/Group 2710.png'

const Bigsection = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 60vh; */
`
const Content=styled.div`
padding: 100px 0;
`
const LeftDiv = styled.div`
/* text-align: right; */
position: relative;

`
const LeftImg = styled.img`
width: 50%;
position: absolute;
right: 90px;
@media (max-width:768px) {
   position: relative;
   margin: auto;
   text-align: center;
   width: 80%;
   right: 0;
}
`
const RightDiv = styled.div`
text-align: left;
padding-top: 70px;
`
const NowIS=styled.p`
color: #A0C632;
font-weight: bold;
font-size: 38px;
line-height: 1.5;
`
const LearnButton=styled.button`
padding: 10px 30px;
border: none;
border-radius: 5px;
background-color: #A0C632;
color: white;
font-size: 17px;
`
const GetButton=styled.button`
padding: 10px 30px;
border: none;
border-radius: 5px;
background-color: #0D3350;
color: white;
font-size: 17px;
margin-left: 20px;
@media (max-width:991px) {
    margin-left: 0;
    display: block;
    margin-top: 5px;
}`

const CallForAquote = () => {
    return <Bigsection>
        <div className="container">
            <Content className="row">
                <div className="col-md-6">
                    <LeftDiv>
                        <LeftImg src={bighouse}></LeftImg>
                    </LeftDiv>
                </div>
                <div className="col-md-6">
                    <RightDiv>
                        <h2>Call For A Quote: 1-818-641-1240</h2>
                        <div style={{paddingTop:"30px"}}>
                        <NowIS>NOW IS THE TIME</NowIS>  
                        <NowIS> TO TRANSFORM YOUR HOME</NowIS>
                        </div>
                        <p>We Have Over 10 Years of Experience in Home Improvement</p>
                        <LearnButton>LEARN MORE </LearnButton><GetButton>GET A QUOTE</GetButton>
                    </RightDiv>
                </div>
            </Content>
        </div>
    </Bigsection>
}
export default CallForAquote