
import Footer from "../landingPage/components/Footer/Footer"
import HeaderForServices from "./HeaderForServices/HeaderForServices"
import styled from "styled-components"
import React from "react"
import bg from '../../assets/ServicesBg.png'
import img from '../../assets/acServices.png'

import house from '../../assets/bigHouseimg.png'
import { useLocation } from "react-router-dom"

// Style
const Hero = styled.div`
background-image:url(${bg});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* height: 100vh; */
`
const LeftDiv = styled.div``

const RightDiv = styled.div``

const Img = styled.img`
width:50%;
margin-right:100px;
`

const Content = styled.div`
padding: 100px 0;
`
const BlackDot=styled.span`
  height: 4px;
  width: 4px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
`
const AirConditioning = () => {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return <div>
        <HeaderForServices></HeaderForServices>
        <Hero>

            <div className="container">
                <div className="row" style={{ padding: "100px 0" }}>
                    <LeftDiv style={{ textAlign: "right" }} className="col-md-6">
                        <Img src={img}></Img>
                    </LeftDiv>
                    <RightDiv className="col-md-6">
                        <Content>
                            <p style={{ fontSize: "16px" }}>HVAC services include construction, installation, and servicing of heating, cooling, and ventilation systems and equipment. HVAC is an acronym for heating, ventilation, and air conditioning. HVAC services organize the regulation of temperature inside a building, using either heating or cooling systems, wall and ceiling ducting, or other HVAC technologies.
                            </p>
                            <ul>
                                       <p style={{fontSize:"25px"}}> Why Us</p>
                            <p><BlackDot></BlackDot> Professional Service</p>
                            <p><BlackDot></BlackDot> HVAC Service</p>
                            <p><BlackDot></BlackDot> HVAC Installation & Repair</p>
                            <p><BlackDot></BlackDot> Heating & Cooling Repair</p>
                            <p><BlackDot></BlackDot> Air Conditioning Repair</p>
                            <p><BlackDot></BlackDot> Vent, Furnace, & Boiler Repair</p>
                            <p><BlackDot></BlackDot> High Customer Satisfaction</p>
                            <p><BlackDot></BlackDot> Residential & Commercial Service</p>
                            </ul>
                            {/* <p style={{ fontSize: "16px" }}>
                                We educate our clients and walk them through the entire bathroom renovation process, allowing them to not just appreciate the value of their new space but to experience the quality of their new space from every aspect. Home Improvement can help you choose layouts, bathroom design ideas, create goals, and completely customize this space to reflect your personal style and needs.
                            </p>

                            <p style={{ fontSize: "22px" }}>Our full array of bathroom remodeling services includes:</p>
                            <ul>
                                <p>3D design layout of your bathroom</li>
                                <p>Cabinet and vanity installation</li>
                                <p>Plumbing installation</li>
                                <p>Electrical installation</li>
                                <p>Fixture and lighting installation</li>
                                <p>Replacing and/or upgrading tile showers</li>
                                <p>Flooring</li>
                                <p>Painting</li>
                            </ul> */}
                        </Content>
                    </RightDiv>
                </div>
            </div>
        </Hero>
        <Footer></Footer>
    </div>
}
export default AirConditioning